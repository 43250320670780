import React, {Component} from "react";
import {connect} from "react-redux";
import {Menu} from "antd";
import {Link} from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";

import Auxiliary from "util/Auxiliary";
import UserProfile from "./UserProfile";
//import AppsNavigation from "./AppsNavigation";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";

class SidebarContent extends Component {

  getNoHeaderClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return "gx-no-header-notifications";
    }
    return "";
  };
  getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };

  render() {
    const {themeType, navStyle, pathname} = this.props;
    const selectedKeys = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split('/')[1];
    return (<Auxiliary>

        <SidebarLogo/>
        <div className="gx-sidebar-content">
          <div className={`gx-sidebar-notifications ${this.getNoHeaderClass(navStyle)}`}>
            <UserProfile/>
          </div>

          {JSON.parse(localStorage.getItem('user')) && JSON.parse(localStorage.getItem('user')).roles[0] === "ROLE_ADMIN" &&
            <CustomScrollbars className="gx-layout-sider-scrollbar">
            <Menu
              defaultOpenKeys={[defaultOpenKeys]}
              selectedKeys={[selectedKeys]}
              theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
              mode="inline">

            <Menu.SubMenu key="dashboard" className={this.getNavStyleSubMenuClass(navStyle)}
                      title={<span> <i className="icon icon-dasbhoard"/>
                      <IntlMessages id="sidebar.dashboard"/></span>}>
                <Menu.Item key="main/dashboard/companies">
                  <Link to="/main/dashboard/companies"><i className="icon icon-modal"/>
                    <IntlMessages id="sidebar.companies"/></Link>
                </Menu.Item>
                <Menu.Item key="main/dashboard/users">
                  <Link to="/main/dashboard/users"><i className="icon icon-user"/>
                    <IntlMessages id="sidebar.users"/></Link>
                </Menu.Item>
                <Menu.Item key="main/dashboard/warehouses">
                  <Link to="/main/dashboard/warehouses"><i className="icon icon-home"/>
                    <IntlMessages id="sidebar.warehouses"/></Link>
                </Menu.Item>
                <Menu.Item key="main/dashboard/contracts/home">
                  <Link to="/main/dashboard/contracts/home"><i className="icon icon-product-grid"/>
                    <IntlMessages id="sidebar.contracts"/></Link>
                </Menu.Item>
                <Menu.Item key="main/dashboard/contracts/tracking">
                  <Link to="/main/dashboard/contracts/tracking"><i className="icon icon-product-grid"/>
                    <IntlMessages id="sidebar.tracking"/></Link>
                </Menu.Item>
            </Menu.SubMenu>
            </Menu>
          </CustomScrollbars>
          }
        </div>
      </Auxiliary>
    );
  }
}

SidebarContent.propTypes = {};
const mapStateToProps = ({settings}) => {
  const {navStyle, themeType, locale, pathname} = settings;
  return {navStyle, themeType, locale, pathname}
};
export default connect(mapStateToProps)(SidebarContent);

