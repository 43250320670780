import React from "react";
import {Redirect,Route, Switch} from "react-router-dom";
import asyncComponent from "util/asyncComponent";


const Dashboard = ({match}) => (
  <Switch>
    <Redirect exact from={`${match.url}/`} to={`${match.url}/companies`}/>
    <Route path={`${match.url}/companies`} component={asyncComponent(() => import('./home/companies/index'))}/>
    <Route path={`${match.url}/buyers`} component={asyncComponent(() => import('./home/buyers/index'))}/>
    <Route path={`${match.url}/sellers`} component={asyncComponent(() => import('./home/sellers/index'))}/>
    <Route path={`${match.url}/users`} component={asyncComponent(() => import('./users/index'))}/>
    <Route path={`${match.url}/warehouses`} component={asyncComponent(() => import('./warehouses/index'))}/>
    <Route path={`${match.url}/contracts/home`} component={asyncComponent(() => import('./contracts/home/index'))}/>
    <Route path={`${match.url}/contracts/tracking`} component={asyncComponent(() => import('./contracts/tracking/index'))}/>
    <Route path={`${match.url}/contracts/reportSession`} component={asyncComponent(() => import('./contracts/reportSession/index'))}/>
    <Route path={`${match.url}/contracts/activeContracts`} component={asyncComponent(() => import('./contracts/activeContracts/index'))}/>
    <Route path={`${match.url}/contracts/reports`} component={asyncComponent(() => import('./contracts/reports/index'))}/>
  </Switch>
);

export default Dashboard;
