import React from "react";
import {Redirect,Route, Switch} from "react-router-dom";
import asyncComponent from "util/asyncComponent";


const Dashboard = ({match}) => (
  <Switch>
    <Redirect exact from={`${match.url}/`} to={`${match.url}/contracts`}/>
    <Route path={`${match.url}/contracts`} component={asyncComponent(() => import('./contracts/index'))}/>
    <Route path={`${match.url}/rsession`} component={asyncComponent(() => import('./rsession/index'))}/>
    <Route path={`${match.url}/reports`} component={asyncComponent(() => import('./reports/index'))}/>
  </Switch>
);

export default Dashboard;
