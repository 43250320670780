import React, {Component} from "react";
import {connect} from "react-redux";
import {Avatar, Popover} from "antd";
import {userSignOut} from "appRedux/actions/Auth";
import {
  BASE_URL
} from "constants/ActionTypes";



class UserProfile extends Component {

  render() {
    var user = JSON.parse(localStorage.getItem('user'));
    const userMenuOptions = (
      <ul className="gx-user-popover">
        <li onClick={() => this.props.userSignOut()}>Logout
        </li>
      </ul>
    );

    return (
        <Popover placement="bottomRight" content={userMenuOptions} trigger="click">
          <Avatar src={user && user.company && user.company.logo ? BASE_URL+`files/${user.company.logo}` : require("assets/images/logo.png")}
                  shape="square"
                  size={200}
                  className="gx-size-80 gx-pointer gx-mr-3" alt=""/>


          <span className="gx-avatar-name">{localStorage.getItem('user_id')}<i
            className="icon icon-chevron-down gx-fs-xxs gx-ml-2"/></span>
        </Popover>

    )
  }
}

export default connect(null, {userSignOut})(UserProfile);
