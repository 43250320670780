import React, {Component} from "react";
import {connect} from "react-redux";
import {Menu} from "antd";
import {Link} from "react-router-dom";
import IntlMessages from "../../util/IntlMessages";
import {
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
} from "../../constants/ThemeSetting";


const SubMenu = Menu.SubMenu;

class HorizontalNav extends Component {

  getNavStyleSubMenuClass = (navStyle) => {
    switch (navStyle) {
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return "gx-menu-horizontal gx-submenu-popup-curve";
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-inside-submenu-popup-curve";
      case NAV_STYLE_BELOW_HEADER:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-below-submenu-popup-curve";
      case NAV_STYLE_ABOVE_HEADER:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-above-submenu-popup-curve";
      default:
        return "gx-menu-horizontal";

    }
  };

  render() {
    const {pathname, navStyle} = this.props;
    const selectedKeys = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split('/')[0];
    return (

      <Menu
        defaultOpenKeys={[defaultOpenKeys]}
        selectedKeys={[selectedKeys]}
        mode="horizontal">

        <SubMenu key="dashboard" className={this.getNavStyleSubMenuClass(navStyle)}
                  title={<span>Sacof</span>}>
            <Menu.Item key="main/dashboard/companies">
              <Link to="/main/dashboard/companies"><i className="icon icon-modal"/>
                <IntlMessages id="sidebar.companies"/></Link>
            </Menu.Item>
            <Menu.Item key="main/dashboard/users">
              <Link to="/main/dashboard/users"><i className="icon icon-user"/>
                <IntlMessages id="sidebar.users"/></Link>
            </Menu.Item>
            <Menu.Item key="main/dashboard/warehouses">
              <Link to="/main/dashboard/warehouses"><i className="icon icon-home"/>
                <IntlMessages id="sidebar.warehouses"/></Link>
            </Menu.Item>
            <Menu.Item key="main/dashboard/contracts/home">
              <Link to="/main/dashboard/contracts/home"><i className="icon icon-product-grid"/>
                <IntlMessages id="sidebar.contracts"/></Link>
            </Menu.Item>
            <Menu.Item key="main/dashboard/contracts/tracking">
                  <Link to="/main/dashboard/contracts/tracking"><i className="icon icon-cascader"/>
                    <IntlMessages id="sidebar.tracking"/></Link>
                </Menu.Item>
        </SubMenu>
      </Menu>
    );
  }
}

HorizontalNav.propTypes = {};
const mapStateToProps = ({settings}) => {
  const {themeType, navStyle, pathname, locale} = settings;
  return {themeType, navStyle, pathname, locale}
};
export default connect(mapStateToProps)(HorizontalNav);

